// based on
// https://freefrontend.com/css-input-text/
// https://codepen.io/lucasyem/pen/ZEEYKdj

import classNames from "classnames";
import "./InputField.css";
import useInputField from "./useInputField";

function InputField(props) {
  const { input, handleChangeInput } = useInputField(props.initialValue || "");
  if (props.refStates) {
    props.refStates[props.id] = input;
  }
  return (
    <div className={classNames("InputField", props.className)}>
      <input
        className="InputField__input"
        placeholder={props.placeholder}
        onChange={handleChangeInput}
        name="name"
        id={props.id}
        value={input}
        type={props.type || "text"}
        required={props.required}
      />
      <label htmlFor={props.id} className="InputField__label">
        {props.placeholder}
      </label>
    </div>
  );
}

export default InputField;

// using the following code
// https://codepen.io/lucasyem/pen/ZEEYKdj
