import {
  convertFromDocument,
  getDocumentId,
  getBaseFirebaseDbUrl,
  convertToDocument,
} from "./common";
import { URLEntity } from "../../UrlEntity/BuildInURL";

const updateDoc = async (collection, id, obj, { httpClient, user }) => {
  try {
    const url = new URLEntity();
    const baseURL = getBaseFirebaseDbUrl(collection, id);
    const docObj = convertToDocument(obj);
    url.setURL(baseURL);
    for (let key in obj) {
      url.appendQueryParam("updateMask.fieldPaths", key);
    }
    let httpOptions = {
      method: "PATCH",
      body: JSON.stringify(docObj),
    };
    httpOptions = await user.pairAuthToHttpRequest(httpOptions);
    let docRefJson = await httpClient.request(url.getURL(), httpOptions);
    docRefJson = {
      id: id,
      data: convertFromDocument(docRefJson),
    };
    console.log("Document updated successfully:", docRefJson);
    return docRefJson;
  } catch (error) {
    console.log("Error updating document:", error);
    throw error;
  }
};

const addDoc = async (collection, obj, { httpClient, user }) => {
  try {
    const url = new URLEntity();
    const baseURL = getBaseFirebaseDbUrl(collection);
    const docObj = convertToDocument(obj);
    url.setURL(baseURL);
    let httpOptions = {
      method: "POST",
      body: JSON.stringify(docObj),
    };
    httpOptions = await user.pairAuthToHttpRequest(httpOptions);
    let docRefJson = await httpClient.request(url.getURL(), httpOptions);
    docRefJson = {
      id: getDocumentId(docRefJson),
      data: convertFromDocument(docRefJson),
    };
    console.log("Document added successfully:", docRefJson);
    return docRefJson;
  } catch (error) {
    console.log("Error adding document:", error);
    throw error;
  }
};

export const addOrUpdateDoc = async (
  collection,
  id,
  obj,
  { httpClient, user }
) => {
  try {
    const docRef = id
      ? updateDoc(collection, id, obj, { httpClient, user })
      : addDoc(collection, obj, { httpClient, user });
    return docRef;
  } catch (error) {
    throw error;
  }
};
