import { useState } from "react";

function useInputField(initialState) {
  const [input, setInput] = useState(initialState);

  const handleChangeInput = (e) => {
    setInput(e.target.value);
  };

  return { input, handleChangeInput, setInput };
}

export default useInputField;
